export const sendSuccess = {
    title: "送信完了",
    icon: "success",
    timer: 1500,
    button: false,
}

export const deleteSuccess = {
    title: "削除完了",
    icon: "success",
    timer: 2000,
    buttons: false,
}

export const willDelete = {
    title: "削除しますか？",
    icon: "info",
    buttons: ["いいえ", "はい"],
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const hitWarning = {
    title: "注意",
    text: "的中数を入力してください",
    icon: "warning",
    timer: 2000,
    button: false,
}

export const inputWarning = {
    title: "注意",
    text: "入力値が正しくありません",
    icon: "warning",
    timer: 2000,
    button: false,
}

export const editWarning = {
    title: "内容は保存されません。",
    text: "終了してもよろしいですか？",
    icon: "warning",
    buttons: ["編集を続ける", "閉じる"],
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const loginError = {
    title: "ログインできませんでした",
    text: "メールアドレスまたはパスワードが違います。お手数ですが、通信環境の良いところでもう一度入力してください。",
    icon: "error",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const networkError = {
    title: "エラー",
    text: "通信にエラーが生じたため、強制的にログアウトします。お手数ですが、再度ログインしてください。\n\n考えられる原因：\n*通信環境の悪いところで利用した。\n*セッションが無効",
    icon: "error",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const sendError = {
    title: "送信できませんでした",
    text: "通信にエラーが生じたため、強制的にログアウトします。お手数ですが、再度ログインしてください。\n\n考えられる原因：\n*通信環境の悪いところで利用した。\n*セッションが無効",
    icon: "error",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const changeError = {
    title: "変更できませんでした",
    text: "通信にエラーが生じたため、強制的にログアウトします。お手数ですが、再度ログインしてください。\n\n考えられる原因：\n*通信環境の悪いところで利用した。\n*セッションが無効",
    icon: "error",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const deleteError = {
    title: "削除できませんでした",
    text: "通信にエラーが生じたため、強制的にログアウトします。お手数ですが、再度ログインしてください。\n\n考えられる原因：\n*通信環境の悪いところで利用した。\n*セッションが無効",
    icon: "error",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const registerError = {
    title: "登録できませんでした",
    text: "もう一度登録お願いします。",
    icon: "error",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const registerSuccess = {
    title: "仮登録完了",
    text: "登録したメールアドレス宛に本登録を行うURLをお送りしましたので、ご確認ください\n\n[注意]\n*現在は仮登録の状態です。ユーザー登録をする場合は必ずメール記載のURLから本登録を行ってください。\n*仮登録は24時間が有効期限です。24時間以内に本登録を行ってください。\n*[@gmai.com]からのメール受信許可をお願い致します。",
    icon: "success",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

///////////////////////

export const typeChangeWarning = {
    title: "本当に役職を変更しますか？",
    text: "役職の変更は全体のタイムラインに流れます。また、役職によって使える機能が異なりますので変更する際は間違わないように注意して下さい。",
    icon: "warning",
    buttons: ["やめる", "変更する"],
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const typeChangeSuccess = {
    title: "変更完了",
    text: "変更が完了しました。\n変更を反映させるため、強制敵にログアウトします。",
    icon: "success",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const sessionDelete = {
    text: "チーム内ユーザーが増えたため、一度ログアウトします。お手数ですが再度ログインし直してください。",
    icon: "warning",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const mychangeLogout = {
    text: "自身の変更を反映するために一度ログアウトします。お手数ですが再度ログインし直してください。",
    icon: "warning",
    button: "Logout",
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

//承認系
export const approvalWait = {
    text: "このアカウントは、ステータスが承認待ちの状態です。\n幹部部員にステータスを変更されるまでお待ちください",
    icon: "warning",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const approvalRefuse = {
    text: "このアカウントは、ステータスが非承認の状態です。\n非承認のためログインできません。幹部部員にお問い合わせください。",
    icon: "error",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const typeChangeError = {
    text: "幹部部員のユーザーが0人になるため、役職を変更することができません",
    icon: "error",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const permissionChangeError = {
    text: "幹部部員の承認済みのユーザーが0人になるため、ステータスを変更することができません",
    icon: "error",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}

export const doubleWarning = {
    text: "すでに記録があるため送信できません。\n的中を変更したい場合は的中カレンダーの各日にちから訂正を行ってください。",
    icon: "warning",
    button: true,
    closeOnClickOutside: false,
    closeOnEsc: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
}
